import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { IdentityPreviewV1 } from '@/sdk';
import { SelectIdentity } from '@/core/SelectIdentity.molecule';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormError } from '@/core/FormError.atom';
import classNames from 'classnames';

export interface SelectIdentityIF extends ValidatingFormComponent<Partial<IdentityPreviewV1>> {
  component: 'SelectIdentityFormComponent';
  name: string;
  placeholder?: string;
  unauthorizedTooltip?: string;
  idForLabel?: string;
  allowGroups?: boolean;
  showDirectory?: boolean;
  includeAllProperties?: boolean;
  startEditable?: boolean;
  multiple?: boolean;
  clearIdentityWhenEmpty?: boolean;
  allowNew?: boolean;
}

export const SelectIdentityFormComponent: React.FunctionComponent<SelectIdentityIF> = (props) => {
  const {
    idForLabel,
    allowGroups,
    showDirectory,
    includeAllProperties,
    startEditable,
    placeholder,
    extraClassNames,
    tooltip,
    unauthorizedTooltip,
    multiple,
    testId = 'selectIdentity',
    name,
    extendValidation,
    validation,
    customErrorText,
    value,
    skipStore,
    clearIdentityWhenEmpty,
    allowNew,
  } = props;

  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && (_.has(formState.dirtyFields, name) || formState.submitFailed);

  const defaultValidation = (value: Partial<IdentityPreviewV1>) => (multiple ? value?.id : !value?.id);

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <div className={classNames('flexRowContainer', extraClassNames)} data-testid={testId}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const formFieldProps = getFormFieldProps(formState, input, meta, props);

          return (
            <SelectIdentity
              setIdentity={formFieldProps.onChange}
              identity={skipStore ? input.value : value}
              placeholder={placeholder}
              tooltip={tooltip}
              unauthorizedTooltip={unauthorizedTooltip}
              idForLabel={idForLabel}
              allowGroups={allowGroups}
              showDirectory={showDirectory}
              includeAllProperties={includeAllProperties}
              startEditable={startEditable}
              multiple={multiple}
              isInvalid={showError}
              clearIdentityWhenEmpty={clearIdentityWhenEmpty}
              allowNew={allowNew}
            />
          );
        }}
      </Field>
      {showError && customErrorText && <FormError errorText={customErrorText} dataTestId={`${testId}Error`} />}
    </div>
  );
};
