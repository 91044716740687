// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { InputGroup, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Icon } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { SelectAssetModal } from '@/core/SelectAssetModal.molecule';
import { itemIconClass } from '@/utilities/utilities';
import { getAssetFromAncestors } from '@/utilities/httpHelpers.utilities';

export interface SelectAssetIF extends ValidatingFormComponent<any> {
  component: 'SelectAssetFormComponent';
  iconPartialTooltipKey: string;
  scopeIds?: string[];
  excludeGloballyScoped?: boolean;
  helpTextKey?: string;
  customErrorText?: string;
}

export const SelectAssetFormComponent: React.FunctionComponent<SelectAssetIF> = (props) => {
  const {
    name,
    value,
    testId = 'selectAsset',
    validation,
    extendValidation,
    onChange,
    iconPartialTooltipKey,
    helpTextKey = undefined,
    customErrorText,
    scopeIds = undefined,
    excludeGloballyScoped = false,
  } = props;

  const { t } = useTranslation();

  const formState = useForm().getState();

  const showError =
    _.has(formState.errors, name) &&
    (_.has(formState.dirtyFields, name) || _.has(formState.dirtyFieldsSinceLastSubmit, name)) &&
    formState.hasValidationErrors;
  const [isSelectingAsset, setIsSelectingAsset] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(value);
  useEffect(() => setSelectedAsset(value), [value]);

  const defaultValidation = (value: any) => !value;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const validateAsset = (item) => Promise.resolve(!appliedValidation(item));
  const [canSave, setCanSave] = useState(false);
  useEffect(() => {
    isSelectingAsset &&
      validateAsset(selectedAsset).then((result) => {
        setCanSave(result);
      });
  }, [selectedAsset, isSelectingAsset]);

  const clearAndClose = () => {
    setIsSelectingAsset(false);
  };

  const onSave = () => {
    onChange(selectedAsset);
    setIsSelectingAsset(false);
  };

  const searchResult = (item) => (
    <div className="flexColumnContainer flexSpaceBetween flexFill ptb2">
      {(item && (
        <>
          <div className="flexColumnContainer flexCenter">
            <Icon
              icon={itemIconClass(item)}
              testId={`${testId}-icon`}
              type="inherit"
              extraClassNames="sq-fairly-dark-gray pl5 pr10"
              large={true}
            />
          </div>
          <div className="flexFill" data-testid={`${testId}-selected`}>
            <div className="searchResultName">
              <span className="simple-word-break">{item.name}</span>
            </div>
            <div className="xsmall pb2 sq-fairly-dark-gray text-italic simple-word-break">
              {(item?.ancestors && getAssetFromAncestors(item.ancestors).formattedName) || item?.datasource?.name}
            </div>
          </div>
        </>
      )) || (
        <div className="sq-fairly-dark-gray text-italic" data-testid={`${testId}-notSelected`}>
          <span>{t('FORM.NO_ASSET_SELECTED')}</span>
        </div>
      )}
    </div>
  );

  const title = <Modal.Title>{t('FORM.SELECT_ASSET')}</Modal.Title>;

  const body = helpTextKey ? (
    <div className="alert alert-info" data-testid={`${testId}-helpText`}>
      {t(helpTextKey)}
    </div>
  ) : (
    <></>
  );

  const footer = (
    <Modal.Footer>
      <div className="flexColumnContainer flexJustifyEnd pt2">
        <div>
          <Button onClick={clearAndClose} label={t('CANCEL')} extraClassNames="mr5" testId={`${testId}-cancel`} />
          <Button
            extraClassNames={canSave ? '' : 'noMouse'}
            onClick={onSave}
            label={t('SAVE')}
            variant="theme"
            disabled={!canSave}
            testId={`${testId}-save`}
          />
        </div>
      </div>
    </Modal.Footer>
  );

  return (
    <>
      <FormFieldWrapper
        wrapperClassNames="flexFill"
        testId={testId}
        showError={showError}
        customErrorText={customErrorText}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const formFieldProps = getFormFieldProps(formState, input, meta, props);
            return (
              <InputGroup>
                <div
                  className={classNames(
                    'width-maximum form-control ptb0 flexColumnContainer flexAlignCenter',
                    formFieldProps.extraClassNames,
                  )}
                  data-testid={`${testId}-value`}>
                  {searchResult(formFieldProps.value)}
                </div>
                <InputGroup.Append>
                  <Button
                    icon="fa-pencil"
                    iconStyle="theme"
                    testId={`${testId}-edit`}
                    onClick={() => {
                      setIsSelectingAsset(true);
                    }}
                  />
                </InputGroup.Append>
              </InputGroup>
            );
          }}
        </Field>
      </FormFieldWrapper>
      {isSelectingAsset && (
        <SelectAssetModal
          header={title}
          body={body}
          footer={footer}
          onClose={clearAndClose}
          assetId={selectedAsset?.id}
          iconPartialTooltipKey={iconPartialTooltipKey}
          onSelect={setSelectedAsset}
          validateAsset={validateAsset}
          scopeIds={scopeIds}
          excludeGloballyScoped={excludeGloballyScoped}
          testId={`${testId}-modal`}
        />
      )}
    </>
  );
};
