import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { AiAssistant } from '@/aiAssistant/AiAssistant.organism';
import _ from 'lodash';
import { FormulaDocumentation } from '@/formula/FormulaDocumentation.organism';
import { ASSISTANT, DOCUMENTATION } from '@/tools/formula/formulaTool.constants';
import { useTranslation } from 'react-i18next';
import { FORMULA_HELP_WIDTH } from '@/tools/formula/FormulaTool.organism';

interface FormulaHelpProps {
  helpView?: string;
  setHelpView: (view: string) => void;
  insertFormulaSnippet: (snippet: string) => void;
  formulaHelpToggleFunction: () => void;
  operators: any;
}

export const FormulaHelp: React.FunctionComponent<FormulaHelpProps> = ({
  helpView = DOCUMENTATION,
  setHelpView,
  insertFormulaSnippet,
  formulaHelpToggleFunction,
  operators,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`flexRowContainer max-width-${FORMULA_HELP_WIDTH} min-width-${FORMULA_HELP_WIDTH}`}>
        <div className="panelTabs flexColumnContainer flexSpaceBetween" data-testid="panelTabs">
          <Tabs
            justify={true}
            transition={false}
            activeKey={helpView}
            onSelect={(tabIndex) => setHelpView(tabIndex || DOCUMENTATION)}>
            <Tab eventKey={DOCUMENTATION} tabClassName="documentationTab" title={t('FORMULA.DOCUMENTATION.TAB_NAME')} />
            <Tab eventKey={ASSISTANT} tabClassName="assistantTab" title={t('FORMULA.ASSISTANT.TAB_NAME')} />
          </Tabs>

          {_.isFunction(formulaHelpToggleFunction) && (
            <div
              className="text-underline mt7 mr7 cursorPointer sq-text-primary nowrap"
              onClick={formulaHelpToggleFunction}>
              {t('FORMULA.HIDE_HELP')}
            </div>
          )}
        </div>

        {helpView === DOCUMENTATION && (
          <div className="flexFill max-width-400 min-width-400 formulaHelp mt10" data-testid="formulaHelp">
            <FormulaDocumentation
              insertFormulaSnippet={insertFormulaSnippet}
              operators={operators}
              functions={_.filter(operators, (operator) => operator.name.indexOf('()') !== -1)}
            />
          </div>
        )}

        {helpView === ASSISTANT && (
          <div className="flexColumnContainer flexFill aiAssistant" data-testid="aiAssistant">
            <AiAssistant insertFormulaSnippet={insertFormulaSnippet} />
          </div>
        )}
      </div>
    </>
  );
};
