// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Checkbox } from '@/core/Checkbox.atom';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import classNames from 'classnames';
import { getFormFieldProps, getFormFieldWrapperProps } from '@/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';
import { FormFieldWrapper } from './FormFieldWrapper';

export interface CheckboxIF extends ValidatingFormComponent<boolean> {
  component: 'CheckboxFormComponent';
  id: string;
  onChange: () => void;
  checkboxLabel: string;
  translateLabel?: boolean;
  type?: 'radio' | 'checkbox';
  disabled?: boolean;
  classes?: string;
  onHelpIconClick?: () => any;
}

export const CheckboxFormComponent: React.FunctionComponent<CheckboxIF> = (props) => {
  const { name, value, validation, classes, skipStore, onHelpIconClick, checkboxLabel } = props;

  const formState = useForm().getState();
  const defaultValidation = (value) => _.isEmpty(_.trim(value));
  const appliedValidation = _.isFunction(validation) ? validation : defaultValidation;

  return (
    <FormFieldWrapper {...getFormFieldWrapperProps(props, ['id', 'tooltip'])}>
      <Field name={name} validate={appliedValidation} type="checkbox">
        {({ input, meta }) => (
          <Checkbox
            {..._.omit(getFormFieldProps(formState, input, meta, props), 'label', 'checkboxLabel')}
            classes={classNames('flexColumnContainer', 'flexFill', 'flexAlignStart', classes)}
            isChecked={skipStore ? input.checked : value}
            onHelpIconClick={onHelpIconClick}
            label={checkboxLabel}
          />
        )}
      </Field>
    </FormFieldWrapper>
  );
};
